<template>
  <AppDialog v-model="show">
    <template #header>
      <AppDialogHeader>
        <h1>{{ title }}</h1>
      </AppDialogHeader>
    </template>

    <p>{{ content }}</p>

    <template #footer>
      <AppDialogFooter>
        <AppButton type="button" theme="text" @click="onBtnClicked(true)">
          {{ buttons.action }}
        </AppButton>
        <AppButton type="button" @click="onBtnClicked(false)">
          {{ buttons.cancel }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { computed, onBeforeUnmount } from '@vue/composition-api'

import AppDialog from '@/components/AppDialog/AppDialog'
import AppDialogHeader from '@/components/AppDialogHeader/AppDialogHeader'
import AppDialogFooter from '@/components/AppDialogFooter/AppDialogFooter'
import AppButton from '@/components/AppButton/AppButton'

export default {
  name: 'RegisterLeaveDialog',
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    buttons: {
      type: Object,
      default: () => ({
        action: 'Exit',
        cancel: 'No',
      }),
    },
  },
  setup(props, { emit }) {
    const show = computed({
      get: () => props.value,
      set: (val) => {
        emit('input', val)
      },
    })

    const onBtnClicked = (exitRegistration) => {
      if (exitRegistration) {
        emit('exitRegistration')
      } else {
        // Hide modal
        show.value = false
      }
    }

    // Window close on registration logic
    window.handleWindowClose = (e) => {
      e.preventDefault()
      e.returnValue = ''
    }
    // Adds the event on initialization
    window.addEventListener('beforeunload', window.handleWindowClose)

    //Removes the event on close
    onBeforeUnmount(() => window.removeEventListener('beforeunload', null))

    return {
      show,
      onBtnClicked,
    }
  },
}
</script>

<style lang="postcss" scoped></style>
