<template>
  <div
    class="activity registration"
    :class="!redirectToOldFlow && activeStepIdx > 0 ? 'stp-registration' : null"
  >
    <AppNavBar
      show-progress-bar="sm"
      :hide-border="getSubmitRegistration"
      :progress-bar-active="getSubmitRegistration"
    >
      <template #left>
        <!-- Submit Registration -->
        <template v-if="getSubmitRegistration">
          <AppLink href="https://transfer.xe.com/" aria-label="XE Logo" class="registration-logo">
            <img class="navbar-logo" src="@/assets/images/xe-logo.svg" alt="XE Logo" />
          </AppLink>
        </template>
        <!-- Normal flow -->
        <template v-else>
          <AppLink v-if="mq.current !== 'xs'" href="https://transfer.xe.com/" aria-label="XE Logo">
            <img class="navbar-logo" src="@/assets/images/xe-logo.svg" alt="XE Logo" />
          </AppLink>
          <AppButton
            v-if="mq.current === 'xs' && activeStepIdx >= 0"
            theme="icon"
            class="icon"
            @click="$router.go(-1)"
          >
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
      </template>
      <template v-if="mq.current === 'xs' && activeStepIdx > 0">
        {{ activeStep.pageTitle }}
      </template>
      <template v-if="activeStepIdx > 0 && !getSubmitRegistration">
        <AppNavBarStepper :steps="steps" :active-step-idx="activeStepIdx" />
      </template>

      <template v-if="!getSubmitRegistration" #right>
        <AppButton theme="icon" class="icon" @click="showLeaveDialog = true">
          <AppIcon name="Close">
            <IconClose />
          </AppIcon>
        </AppButton>
      </template>
    </AppNavBar>

    <!-- Display Child Route Form -->
    <RouterView v-if="loaded" />
    <RegisterLeaveDialog
      v-model="showLeaveDialog"
      :title="leaveDialog.title"
      :content="leaveDialog.content"
      :buttons="leaveDialog.buttons"
      @exitRegistration="exitRegistration"
    />
  </div>
</template>

<script>
import { ref, reactive, computed, onBeforeMount, watch } from '@vue/composition-api'
import AppNavBar from '@/components/AppNavBar/AppNavBar'
import AppButton from '@/components/AppButton/AppButton'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppLink from '@/components/AppLink/AppLink'
import { IconClose, IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import { useMediaQuery } from '@/composables/useMediaQuery'
import AppNavBarStepper from '@/components/AppNavBarStepper/AppNavBarStepper'
import RegisterLeaveDialog from './RegisterLeaveDialog'
import useSessionTimeout from '@/composables/useSessionTimeout/useSessionTimeout'
import { useDashboardStore } from '@/stores/dashboard'

import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { storeToRefs } from 'pinia'
import { useStore } from '@/composables/useStore'
import { useAuthStore } from '../../stores/auth'
import { useAppStore } from '@/stores/app'
import { useRegistrationStore } from '@/stores/registration'

export default {
  name: 'Register',
  components: {
    AppNavBarStepper,
    AppButton,
    AppIcon,
    AppNavBar,
    IconClose,
    AppLink,
    IconChevronLeft,
    RegisterLeaveDialog,
  },
  setup(_props, { root: { $router } }) {
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const showLeaveDialog = ref(false)
    const redirectToOldFlow = computed(() => registrationStore.redirectToOldFlow)
    const store = useStore()
    const dashboardStore = useDashboardStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const registrationStore = useRegistrationStore()

    const mq = reactive(useMediaQuery())

    const { steps, activeStepIdx, activeStep, getStepperItems } = storeToRefs(
      corporateRegistrationStore
    )

    const stepperOptions = computed(() => registrationStore.stpConfig?.stepperOptions)

    const getSubmitRegistration = computed(() => {
      return registrationStore.isSubmitRegistration
    })

    // Set registration config and content
    registrationStore.setRegistrationConfig()

    const registrationContent = computed(() => registrationStore.registrationConfig?.content)
    const {
      components: { leaveDialog },
    } = registrationContent.value

    // Set active options based on the STP rules
    watch(redirectToOldFlow, () => {
      steps.value = registrationStore.getStepOptions
    })

    // Initialize STP features
    registrationStore.initStpConfig() // Initialize STP config JSON
    dashboardStore.setPendingActionsConfig()

    // Registration Setup
    onBeforeMount(async () => {
      let continueFlow = await authStore.checkGso()
      if (continueFlow) {
        continueFlow = await corporateRegistrationStore.checkRedirect($router)
        registrationStore.checkRedirectSimplifiedFlow() // Redirect for STP flow
      }
      if (continueFlow) {
        await appStore.setup()
        await corporateRegistrationStore.fetchCountryList()
        // Set timeout to logout user after 15 mins of inactivity
        useSessionTimeout(store)
        await appStore.setLoadedState(continueFlow)
      }
    })

    const loaded = computed(() => {
      return appStore.loaded
    })

    const exitRegistration = async () => {
      await authStore.leaveRegistration()
    }

    return {
      mq,
      steps,
      activeStepIdx,
      activeStep,
      showLeaveDialog,
      getSubmitRegistration,
      stepperOptions,
      getStepperItems,
      redirectToOldFlow,
      exitRegistration,
      leaveDialog,
      loaded,
    }
  },
}
</script>

<style lang="postcss">
.registration {
  @apply min-h-screen;

  @screen sm {
    @apply bg-gray-darker;
  }

  &-logo {
    @apply invisible;

    @screen sm {
      @apply visible;
    }
  }
}

/* Navbar Logo */
.navbar-logo {
  @apply flex justify-center items-center h-14 w-14;
  @apply -ml-1_5;
  @apply cursor-pointer;
  img {
    margin-top: -2px;
    width: 42px;
  }
  @screen sm {
    min-width: 3.3rem;
    @apply h-16;
    img {
      @apply w-12;
    }
  }
}

/* Card Styles */
.card {
  @apply border-none !important;

  @screen sm {
    @apply mb-16 !important;
  }
}

/* Card header styles */
.card-header {
  @apply mb-0 pt-9 !important;
}

/* Card content styles */
.card-content {
  @apply pt-6 !important;

  @screen sm {
    @apply px-10 !important;
    @apply pb-8 !important;
  }
}

/* Card footer styles */
.card-footer {
  @apply shadow-none !important;
  @apply px-4 pb-4 z-50;

  .button {
    @apply rounded-lg !important;

    &.button--primary {
      &:disabled:not(.button--loading) {
        @apply text-white bg-blue-button-disabled !important;
      }
    }
  }
  @screen sm {
    padding: inherit !important;
    .button {
      @apply w-full !important;
    }
  }
}

.step-wrapper {
  @screen sm {
    @apply flex justify-center !important;
  }
}

/* Font weight of the Stepper text */
.step .step-text {
  @apply font-medium;
}

/* Checkbox Styles */
::v-deep .app-input-checkbox {
  .halo {
    @apply w-6 h-6 mr-0 !important;
    .checkbox {
      @apply border-gray-light !important;
    }
  }
}

/* Global styles for corp-reg STP flow */
.card-stp {
  @apply pt-0;
  @apply overflow-visible !important;

  & .card-content {
    @apply overflow-y-visible;
  }
}
</style>
