<template>
  <div class="step-wrapper">
    <div
      v-for="(step, idx) in steps"
      :key="idx"
      class="step"
      :class="{
        'step--active': stepIsActive(idx),
        'step--complete': stepIsComplete(idx),
        'step--hidden': step.hidden,
      }"
    >
      <div class="step-progress"></div>
      <span class="step-text">
        {{ step.title }}
      </span>
    </div>
  </div>
</template>

<script>
import { inject, watch, onUnmounted } from '@vue/composition-api'
const maxSteps = 5
export default {
  name: 'AppNavBarStepper',
  props: {
    steps: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
      validation: (val) => {
        return val.length > 0 && val.length <= maxSteps
      },
    },
    activeStepIdx: {
      type: Number,
      default: 0,
      validation: (val) => {
        return val <= maxSteps - 1
      },
    },
  },
  setup(props) {
    const progressBar = inject('progressBar')
    progressBar.active = true

    const stepIsActive = (idx) => {
      return idx === props.activeStepIdx
    }
    const stepIsComplete = (idx) => {
      return idx < props.activeStepIdx
    }

    const validateActiveStepIdx = () => {
      if (
        props.activeStepIdx > props.steps.length - 1 ||
        props.activeStepIdx < 0
      ) {
        throw new Error(
          `Invalid activeStepIdx ${props.activeStepIdx}: Out of range`
        )
      }
    }
    const updateProgressBarValue = () => {
      if (progressBar !== undefined) {
        const unit = 100 / (props.steps.length + 1)
        progressBar.percent = unit * (props.activeStepIdx + 1)
      }
    }

    // Validate the active step is in range
    watch(() => props.activeStepIdx, validateActiveStepIdx, { immediate: true })
    watch(() => props.activeStepIdx, updateProgressBarValue, {
      immediate: true,
    })

    onUnmounted(() => {
      progressBar.active = false
    })

    return {
      stepIsActive,
      stepIsComplete,
    }
  },
}
</script>

<style scoped>
.step-wrapper {
  @apply hidden;
  @apply pt-1;
  @screen md {
    @apply flex justify-center;
  }
}

.step--hidden {
  display: none;
}

.step {
  @apply type-caption font-bold text-tertiary-text;
  @apply text-center;
  @apply relative;
  @apply pt-6;
  @apply w-32;
  .step-text {
    @apply w-32 block truncate;
  }
  .step-progress {
    &::before,
    &::after {
      content: '';
      @apply h-0_5;
      @apply top-0;
      @apply bg-gray-lighter;
    }
    &::before {
      @apply absolute left-0;
      right: 50%;
    }
    &::after {
      @apply absolute right-0;
      left: 50%;
    }
  }
  &:first-child {
    .step-progress {
      &::before {
        @apply hidden;
      }
      &::after {
        @apply rounded-l-full;
      }
    }
  }
  &:last-child {
    .step-progress {
      &::before {
        @apply rounded-r-full;
      }
      &::after {
        @apply hidden;
      }
    }
  }
  &.step--active {
    /* The round active dot*/
    &::after {
      content: '';
      @apply h-2 w-2 bg-orange rounded-full;
      @apply absolute top-0 z-10;
      left: 50%;
      margin-left: -3px;
      margin-top: -3px;
    }
    @apply text-primary-text;
    .step-progress {
      &::before {
        @apply bg-orange;
      }
    }
  }
  &.step--complete {
    .step-progress {
      &::before {
        @apply bg-orange;
      }
    }
    .step-progress {
      &::after {
        @apply bg-orange;
      }
    }
  }
}
</style>
