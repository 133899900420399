<template>
  <a
    class="link"
    :href="href"
    :target="target"
    rel="nofollow noreferrer noopener"
  >
    <div v-if="$slots.leftIcon" class="link-left-icon">
      <!-- @slot leftIcon: For the left Icon -->
      <slot name="leftIcon" />
    </div>
    <!-- @slot default: link content -->
    <slot />
    <div v-if="$slots.rightIcon" class="link-right-icon">
      <!-- @slot rightIcon: For the right Icon -->
      <slot name="rightIcon" />
    </div>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="postcss" scoped>
.link {
  @apply inline-flex items-center;
  @apply text-orange font-bold;

  &:hover {
    @apply underline;
  }
  &:active {
    @apply text-orange-button-pressed;
  }

  .link-left-icon,
  .link-right-icon {
    @apply flex items-center;
  }
  .link-left-icon {
    @apply mr-1_5;
  }
  .link-right-icon {
    @apply ml-1_5;
  }
}
</style>
